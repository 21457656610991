//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageHeader from '@/components/page-header';
import HeaderFormItem from '@/components/page-header/header-form-item';

export default {
    name: 'list',
    inject: ['hideLoading', 'showLoading'],
    components: { PageHeader, HeaderFormItem },
    data() {
        return {
            queryParams: {
                pageSize: 10,
                pageNum: 1,
                startTime: '',
                endTime: '',
            },
            dataPickerValue: [], //接收时间选择器的值
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        logList() {
            return this.$store.state['logManagement'].logList;
        },
        logListTotal() {
            return this.$store.state['logManagement'].logListTotal;
        },
    },
    created() {
        this.getLogList();
    },
    mounted() {
        this.hideLoading();
    },
    methods: {
        search() {
            this.queryParams.pageNum = 1;
            this.getLogList();
        },
        //获取日志列表
        async getLogList() {
            this.showLoading();
            if (this.dataPickerValue) {
                this.queryParams.startTime = this.dataPickerValue[0];
                this.queryParams.endTime = this.dataPickerValue[1];
            } else {
                this.queryParams.startTime = '';
                this.queryParams.endTime = '';
            }
            let res = await this.$store.dispatch('logManagement/getLogList', this.queryParams);
            this.hideLoading();
            if (res.code !== 200) {
                this.$notify({
                    title: res.code,
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                    position: 'bottom-right',
                });
            }
        },
        //时间选项值发生变化
        dataPickerChange() {
            // if(this.dataPickerValue) {
            //     this.queryParams.startTime= this.dataPickerValue[0];
            //     this.queryParams.endTime= this.dataPickerValue[1];
            // }
        },
        handleSizeChange(val) {
            this.queryParams.pageNum = 1;
            this.queryParams.pageSize = val;
            this.getLogList();
        },
        handleCurrentChange(val) {
            this.queryParams.pageNum = val;
            this.getLogList();
        },
    },
};
